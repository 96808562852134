import { useNavigate, useParams } from "react-router-dom";
import AdminWrapper from "../../Wrapper";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { LangContext } from "../../../../context/LangContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/error-handler";
import { getFullname } from "../../../../utils/main";
import Loading from "../../../../components/loading/Loading";
import PatientGeneralData from "./general-data";
import Topbar from "../../../../components/topbar/topbar";
import PatientMainData from "./main-data";
import PatientRedFlags from "../../../../components/red-flags/red-flags";
import PatientDocuments from "./documents";
import PatientChangeLog from "../../../../components/changelog/changelog";
import PatientParents from "./parents";
import PatientVisits from "./_visits";
import PatientAnalyses from "./_analyses";
import Button from "../../../../components/button/button";

export default function PatientView({}) {
    const params = useParams()
    const navigate = useNavigate()
    const {isReady, logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const [model, setModel] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    useEffect(refresh, [isReady, params?.id])
    function refresh() {
        if(isReady && params?.id && !isLoading){
            setIsLoading(true)
            AxiosHelper.getPatient(params?.id).then((result)=>{
                if(result.status == 200){
                    setModel(result.data?.model)
                }
            }).catch((e)=>handleErrors(e, logout))
            .finally(()=>setIsLoading(false))
        }
    }
    const pages = [
        {text:langDictionary?.patients, link:"/patients"},
        {text:getFullname(model, true), link:"/patients/"+params?.id}
    ]
    return(
        <AdminWrapper isSidebar={false} mainClass="fw-500 text-main">
            {
                isLoading || !model?.id ?
                <Loading/>
                :
                <>
                <Topbar pages={pages} className="mb-3" endContent={
                    <>
                    <Button theme="light" className="text-slatgray" onClick={refresh}>
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                    <Button theme="danger" className="text-white ms-2" onClick={() => {
                        if (window.confirm(langDictionary?.confirm_delete)) {
                            AxiosHelper.deletePatient(params?.id).then(()=>navigate(-1)).catch((e) => handleErrors(e, logout));
                        }
                    }}>
                        <i className="bi bi-trash2 me-2"/>
                        {langDictionary?.delete}
                    </Button>
                    </>
                }/>
                <div className="row">
                    <div className="col-md-4">
                        <PatientGeneralData model={model} setModel={setModel} isUploadable={true}/>
                        <PatientMainData model={model}/>
                        <PatientRedFlags patientId={model?.id} data={model?.red_flags} setData={(red_flags)=>setModel({...model, red_flags})}/>
                        <PatientParents model={model} children={model.children} setModel={setModel}/>
                        <PatientDocuments _documents={model?.documents ? model?.documents : []} modelId={model?.id}/>
                        {
                            model.change_logs && model.change_logs?.length ?
                            <PatientChangeLog data={model?.change_logs || []}/>
                            :
                            null
                        }
                    </div>
                    <div className="col-md-8">
                        {/* <PatientTimeline/> */}
                        <PatientVisits model={model}/>
                        <PatientAnalyses model={model}/>
                    </div>
                </div>
                </>
            }
        </AdminWrapper>
    )
}