import { useEffect } from "react";

export default function Modal({id, saveText="Ýatda sakla", closeText="Ret etmek", saveFunction=()=>{}, isFooter=true, isHeader=true,
    modalBodyClass = 'modal-body', isBody = true, headerContent='', isScrollable = false, isBackdropStatic = true, modalContentClass = '',
    modalPosition="modal-dialog-centered", modalSize="", headerClassName='', ...props}){
    useEffect(()=>{
        return function(){
            document.querySelector('.modal-backdrop')?.remove()
        };
    }, [])
    return (
        <div class="modal fade" id={id} tabindex="-1" style={{display: "none"}} data-bs-backdrop={isBackdropStatic ? "static" : 'a'} aria-hidden="true">
            <div class={`modal-dialog ${modalPosition} ${modalSize} ${isScrollable && 'modal-dialog-scrollable'}`}>
                <div class={"modal-content "+modalContentClass}>
                {
                    isBody ?
                    <>
                        {
                            isHeader ?
                            <div className={"modal-header "+headerClassName}>
                                {headerContent}
                            </div>
                            : null
                        }
                        <div class={modalBodyClass}>
                            {
                                props.children
                            }
                        </div>
                        {
                            isFooter ?
                            <div class="modal-footer">
                                <button type="button" class="btn btn-link-secondary" data-bs-dismiss="modal">{closeText}</button>
                                <button type="button" class="btn btn-link-teal" onClick={saveFunction}>{saveText}</button>
                            </div>
                            : null
                        }
                    </>
                    :
                    props.children
                }
                </div>
            </div>
        </div>
    )
}