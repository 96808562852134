import { Link } from "react-router-dom";
import Sort from "../components/sort/sort";
import { genders, getFormattedDate, getFullname, regionTypes } from "./main";
import AxiosHelper from "./API/AxiosHelper";
import { appendNotification } from "../components/notification";
import { useContext } from "react";
import { LangContext } from "../context/LangContext";
import CONSTANTS from "./constants";
import { handleErrors } from "./error-handler";
import { AuthContext } from "../context/AuthContext";

export const PatientTableItems = (filter, setFilter, isRegister=true, isComing=true, refresh=()=>{},)=>{
    const {langDictionary} = useContext(LangContext)
    const {logout} = useContext(AuthContext)
    return [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:'ID', content:(item)=>{
                return <span className="text-secondary fw-bold">{item?.id}</span>;
            }
        },
        {
            text:<Sort text={langDictionary?.FIO} filter={filter} setFilter={setFilter} targetAttribute='surname'/>, content:(item)=>{
                return (
                    <span className="text- ms-2 fw-bold">
                    {
                        item?.isAnonim ?
                        langDictionary?.isAnonim
                        :
                        getFullname(item, true)
                    }
                    </span>
                )
            }
        },
        {
            text:<Sort text={langDictionary?.birth_date} filter={filter} setFilter={setFilter} targetAttribute='birth_date'/>, content:(item)=>{
                const bdate = new Date(item?.birth_date);
                return (
                    <span className="text-dark ms-2 fw-bold">
                    {
                        item?.isAnonim ?
                        null
                        :
                        getFormattedDate(bdate, '-')
                    }
                    </span>
                )
            }
        },
        {
            text:<Sort text={langDictionary?.registered_region} filter={filter} setFilter={setFilter} targetAttribute='region'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {
                            item?.isAnonim ?
                            ''
                            :
                            item?._registered_region ? `${item?._registered_region?.name} ${String(regionTypes[item?._registered_region?.type] || '').substring(0, 3).toLowerCase()}.` : '-//-'
                        }
                    </span>
                )
            }
        },
        {
            text:<Sort text={langDictionary?.living_region} filter={filter} setFilter={setFilter} targetAttribute='region'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {
                            item?.isAnonim ?
                            ''
                            :
                            item?._living_region ? `${item?._living_region?.name} ${String(regionTypes[item?._living_region?.type] || '').substring(0, 3).toLowerCase()}.` : '-//-'
                        }
                    </span>
                )
            }
        },
        {
            text:<Sort text={langDictionary?.gender} filter={filter} setFilter={setFilter} targetAttribute='gender'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-primary text-slatgray ms-2 fw-bold">
                        {genders[item?.gender]}
                    </span>
                )
            }
        },
        {
            text:<Sort text={langDictionary?.blood_group} filter={filter} setFilter={setFilter} targetAttribute='blood_group'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-danger text-danger ms-2 fw-bold">
                        {CONSTANTS.BLOODS[item?.blood_group] || '-//-'}
                    </span>
                )
            }
        },
        {
            text:langDictionary?.documents, content:(item)=>{
                return (
                    <div className="d-grid">
                    {
                        item?.documents?.map((document)=>{
                            return(
                                <div className="d-flex align-items-center mb-1">
                                    <span className="badge bg-accent-secondary text-secondary border ms-2 fw-bold">
                                        <span className="text-small me-1">
                                            {document?._doc_type?.short_name}:
                                        </span>
                                        {document?._serial_key?.name} {document?.number}
                                    </span>
                                </div>
                            )
                        })
                    }
                    </div>
                )
            }
        },
        // {
        //     text:<Sort text={langDictionary?.death_date} filter={filter} setFilter={setFilter} targetAttribute={"birth_date"}/>, content:(item)=>{
        //         if(item?.post_mortem){
        //             const date = new Date(item?.post_mortem?.date);
        //             return (
        //                 <span className="text-dark fw-bold">
        //                     {getFormattedDate(date, '-')}
        //                 </span>
        //             )
        //         }else{
        //             return('-')
        //         }
        //     }
        // },
        {
            text:<Sort text={langDictionary?.created_at} filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '-')}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <Link to={'/patients/'+item?.id} className="btn btn-sm bg-accent-secondary text-dark m-1">
                            <i className="bi bi-eye"></i>
                        </Link>
                    </div>
                )
            }
        }
    ]
}

function handleRemove(langDictionary, item, refresh, logout) {
    if (window.confirm(langDictionary?.confirm_patient_delete)) {
        AxiosHelper.deletePatient(item?.id).then((result) => {
            if (result.status == 200) {
                appendNotification(`${langDictionary?.patient} ${(langDictionary?.successfully_deleted + '').toLowerCase()}`);
                refresh();
            }
        }).catch((e) => handleErrors(e, logout));
    }
}
