import { useEffect, useState } from "react"
import Button from "../button/button"

export default function SearchInput({setFilter, filter}){
    const [text, setText] = useState('')
    useEffect(()=>setText(filter?.text), [filter?.text])
    return (
        <div className="w-100 position-relative px-3">
            <input type="text" className="form-control form-control-sm"  placeholder="Gözleg..." value={text} 
                onChange={(e)=>setText(e.target.value)} onKeyDown={(e)=>{
                    if(e.code === 'Enter'){
                        setFilter({...filter, text})
                    }
                }}
                    style={{paddingLeft:35+'px'}}
                />
            <i className="bi bi-search position-absolute end-0 top-50 translate-middle-y me-4" onClick={()=>setFilter({...filter, text})}/>
            <Button theme="light" buttonSize="little" className="rounded-pill position-absolute top-50 start-0 translate-middle-y ms-4"
                onClick={()=>{
                    text ?
                    setFilter({...filter, text:''})
                    :
                    navigator.clipboard.readText().then((text)=>{
                        setFilter({...filter, text})
                    })
                }}>
                {
                    text ?
                    <i className="bi bi-x-lg"/>
                    :
                    <i className="bi bi-clipboard"/>
                }
            </Button>
        </div>
    )
}